@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import 'scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  list-style: none;
  text-decoration: none;
  background: none;
  font:
    400 1rem 'Roboto',
    sans-serif;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #d3d7d9;
  }
  ::-webkit-scrollbar-thumb {
    background: #52606f;
    &:hover {
      background: #3b4147;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

html,
body {
  background: $nord6;
}
