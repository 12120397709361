.cursor {
  display: inline-block;
  width: 2px;
  height: 11px;
  background-color: #000;
  margin-left: 2px;
  margin-top: 2px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
