@import '../../../scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(#000, 0.6);
  overflow-y: auto;
  backdrop-filter: blur(1px);
  &.modalEnter {
    opacity: 0;
    .modalWrap {
      transform: scale(0.9) translateY(50px);
    }
  }
  &.modalEnterActive {
    transition: 150ms ease-in-out;
    opacity: 1;
    .modalWrap {
      transition: 150ms ease-in-out;
      transform: scale(1) translateY(0);
    }
  }
  &.modalExit {
    opacity: 1;
    .modalWrap {
      transform: scale(1) translateY(0);
    }
  }
  &.modalExitActive {
    transition: 150ms ease-in-out;
    opacity: 0;
    .modalWrap {
      transition: 150ms ease-in-out;
      transform: scale(0.9) translateY(50px);
    }
  }
  .modalWrap {
    margin: 0 auto;
    background: white;
    border-radius: 8px;
    width: 500px;
    max-height: 90%;
    height: auto;
    overflow-y: auto;
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      font-size: 1.5rem;
      font-weight: 600;
      color: $nord1;
    }

    .items {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      .item {
        gap: 7px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid $nord6;
        padding: 1.25rem;
        cursor: pointer;
        transition: 80ms ease-in-out;
        font-size: 0.75rem;

        &__desc {
          display: flex;
          justify-content: space-between;
          color: $x-nord1;
          p {
            font-size: 1.25rem;
          }
        }

        &__title {
          display: flex;
          justify-content: center;
          color: $x-nord1;
          font-size: 1.125rem;
        }

        &:hover {
          background: $nord6;
        }
      }

      .item__active {
        background: $nord4;
        border: 1px solid $nord10;

        &:hover {
          background: $nord4;
          border: 1px solid $nord10;
        }
      }
    }

    .validation_text {
      color: $nord11;
      height: 20px;
    }

    .button {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}
