@import '../../scss';

.result {
  height: min-content;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
  .resultBoxGroup {
    position: sticky;
    top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 400px;
  }
  .resultBox {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding: 40px 20px;
    background: white;
    .resultBox_title {
      font-size: 1.25rem;
      color: $x-nord1;
      font-weight: 400;
    }
    .resultBox_icon_success {
      color: $nord14;
    }
    .resultBox_icon_error {
      color: $nord11;
    }

    .selected_rec_text {
      color: $nord14;
      font-size: 1.25rem;
      font-weight: 400;
    }

    .resultBox_resultText {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: $x-nord1;
        font-size: 1.125rem;
        font-weight: 400;
      }
    }
  }
  .pdf {
    width: 100%;
    background: white;
    border-radius: 8px;
    padding: 30px;
  }
  .links {
    display: flex;
    gap: 20px;
    .linkBttn {
      border-radius: 4px;
      padding: 10px 8px;
      border: 1px solid #7e7e7e;
    }
  }
}
.red {
  color: $nord11 !important;
}

.green {
  color: $nord14 !important;
}
.shap_value {
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 30px;
  height: fit-content;
  .shap_value_title {
    font-size: 1.5rem;
    text-align: center;
  }
}
.loading_shap {
  padding-top: 30px;
}
.gpt_text {
  font-size: 0.875rem;
  color: $x-nord1;
}
.links {
  display: flex;
  gap: 20px;
  .linkBttn {
    border-radius: 4px;
    padding: 10px 8px;
    border: 1px solid #7e7e7e;
  }
}

.container_rec {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  background: white;

  &__title {
    font-size: 1.25rem;
    color: $x-nord1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: $x-nord1;
      font-size: 1.125rem;
      font-weight: 400;
    }
  }
}
